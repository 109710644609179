const NotificationModal = ({ onClose, children, enableClose }) => {
    return (
      <div id='nofication-modal' style={{ zIndex: 20000}} className='w-[100vw]  h-[100vh] bg-[rgba(0,0,0,0.3)] fixed left-0 top-0'>
           <div className='flex justify-center items-center w-full h-full p-0 m-0'>
              <section className='bg-white w-[340px] md:w-[500px] p-3 h-auto rounded-lg'>
                 { enableClose 
                  && <h5 className='flex justify-end'>
                    <span 
                       className='bi bi-x-lg dark:text-slate-900 text-lg' 
                       onClick={onClose} 
                    />
                   </h5>
                   }
                 <section className="p-5">
                    {children}
                 </section>
              </section>
           </div>
      </div>
    )
  }
  
  export default NotificationModal