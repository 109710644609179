import React, { useEffect, useState } from 'react'
import { Link } from "react-router-dom";
import MockAssessment from './components/MockAssessment';
import { api } from '../../../../../api';
import { useQuery } from 'react-query';
import Input from '../../../../../components/input/Input';
import Loader from '../../../../../components/loader/Loader';
import EmptyData from '../../../../../components/EmptyData';

const MockExams = () => {      

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(8);
  const [search, setSearch] = useState('')
  const user = JSON.parse(sessionStorage.getItem("user"));

    const {
      isLoading,
      data: mockAssessment,
    } = useQuery(["user-group-practice-test-assessment", search, currentPage, perPage],
      async () => {
        const data = await api.getPracticeExams({
          search, perPage, currentPage, id: user?.user?.user_id
        });
        return data;
      },
      {
        onSuccess: (data) => {
          if (data?.data?.upcoming_exams?.length) {
            setHasExams(false);
          }
          //setAssessment({ ...assessment, viewAsessments: { ...data?.data } });
        },
        select: response => response?.data?.upcoming_exams
      },
    );

    useEffect(() => {
      window.onbeforeunload = function(event) {
            event.preventDefault();
            event.returnValue = '';
          }
     sessionStorage.setItem('active-page-view', 'mock-page')
    }, []);

  return (
    <div>       
        <section className='jumbotron bg-white p-4'>
            <Link to={'/candidate/assessments/view-assessments'} className='text-lg'>
              Exam
            </Link>
            <span className='mx-2'>/</span>
            <Link to={'/candidate/assessments/view-assessments'} className='text-lg text-customGreen'>
                 View Exams
            </Link>
            <div className='md:flex md:justify-between items-center mt-8'>
         <p className='text-[13px] bg-gray-100 p-3'>
            <span className='bi bi-lightbulb'></span> Tips:
            Did you know that you can take practice exam below with random questions to
            help you prepare better ahead. You can also view your mock exam result once you completed 
            the mock exam.
        </p>
         </div>         
        </section>      
        <div className="text-white md:flex items-center justify-between bg-darkBlue my-4 py-4 px-4 mx-1 rounded-lg">
             <h5 className="max-[500px]:mb-3">
              Practice Exams &nbsp;{" "}
              ({mockAssessment?.total})
             </h5>
             <div className="w-[300px] text-slate-950">
                <Input 
                  type='search'
                  placeholder="Search exams"
                  onChange={ event => setSearch(event.target.value)}
                />
            </div>
          </div>              
         <div className='pt-3 pb-12 px-3'>  
             { isLoading ? <Loader />
              :
               mockAssessment?.data?.length ?
              <MockAssessment
               exams={mockAssessment}
               isLoading={isLoading}
               currentPage={currentPage}
               setPerPage={setPerPage}
               perPage={perPage}
               setCurrentPage={setCurrentPage}
             /> :
             <EmptyData 
              text={ 
                search !==  '' ? 'Sorry, no result found for your search'  : 
                'Sorry, you dont have any practice exam yet!'
                }
            /> 
            }
          </div>              
    </div>
  )
}

export default MockExams