import React, { useMemo, useState } from 'react'
import { UpdatedRequestTable } from '../../../../../../components/table/RequestTable';
import { convertTo12Hour } from '../../../../../../utils/dateTimeFormatter';
import { useNavigate } from 'react-router-dom';
import { clearFlaggedQuestion } from '../../../../../../features/candidate-exam/candidate-exam-slice';
import { useDispatch } from 'react-redux';
import UnlockAssessmentModal from './unlockAssessmentModal';

const VirtualExams = ({
          exams, isLoading, currentPage, setCurrentPage, perPage, setPerPage,
          refetch,
        }) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [isLocked, setIsLocked] = useState(false)

    const handleActionClick = (row) => {
      if(row?.exam_is_locked){
        sessionStorage.setItem('active-exam',  JSON.stringify(row))
        setIsLocked(true)
      }
      else {
        dispatch(clearFlaggedQuestion());
        window.sessionStorage.setItem("active-assessment-id", row?.exam_id);
        window.sessionStorage.setItem("active-assessment-user-id", row?.user_exam_id);
        window.sessionStorage.setItem("active-assessment", JSON.stringify(row));
        window.sessionStorage.setItem("time_left", row?.time_left);
        window.sessionStorage.setItem("duration", row?.exam_duration);
        window.sessionStorage.setItem("ql", row?.number_of_questions);
        window.sessionStorage.setItem("starting-index", row?.number_of_questions_answered);
        navigate("/candidate/assessments/instructions");
      }
     }

    let columns = useMemo(
        () => [
          {
            title: "ID",
            selector: null,
            key: "id",
            render: ({ index }) =>
              index +
              1 +
              ((exams?.current_page || 1) - 1) *
                (exams?.per_page || perPage),
          },
          /*{
            title: "Client Name",
            selector: "client_name",
            key: "client_name",
            render: ({ item }) => {
              return (
                <button
                  className="text-baseFont"
                >
                  {item} 
                </button>
              );
            },
          },*/
          {
            title: "Exam Name",
            selector: "exam_name",
            key: "exam_name",
          },
          {
            title: "Duration",
            selector: "exam_duration",
            key: "exam_duration",
            render: ({ item }) => {
                return (
                  <button
                    className="text-baseFont"
                  >
                    {item} mins 
                  </button>
                );
              },
          }, 
          {
            title: "Questions",
            selector: "number_of_questions",
            key: "number_of_questions",
            render: ({ item, row }) => {
                return (
                  <button
                    className="text-baseFont pl-3"
                  >
                    { row?.number_of_questions_answered ? 
                       <span className='flex'>
                           <del className='text-red-500'>{item}</del>
                           <span className='ml-2'>
                             { parseInt(item)  - row?.number_of_questions_answered}
                           </span>
                       </span>
                    : item } 
                  </button>
                );
              },
          },
          {
            title: "Start Date",
            selector: "start_date",
            key: "start_date",
            render: ({item}) => {
              return <span>{`${ new Date(item).toLocaleDateString()}`}</span>
            }
          },
          {
            title: "End Date",
            selector: "end_date",
            key: "end_date",
            render: ({item}) => {
              return <span>{`${ new Date(item).toLocaleDateString()}`}</span>
            }
          },
          {
            title: "Start Time",
            selector: "start_time",
            key: "start_time",
            render: ({item}) => {
              return <span>{`${convertTo12Hour(item)}`}</span>
            }
          },
          {
            title: "End Time",
            selector: "end_time",
            key: "end_time",
            render: ({item}) => {
              return <span>{`${convertTo12Hour(item)}`}</span>
            }
          },
          {
            title: "Action",
            selector: null,
            key: null,
            render: ({item, row}) => {
              return <button 
                       className='bg-primary rounded-full text-white py-2 px-5 text-sm'
                       onClick={() => handleActionClick(row)}
                      >
                       { row?.exam_is_locked ? 'Unlock Exam' : 
                        row?.number_of_questions_answered ? 'Continue' :  'Start Exam'
                       }
                    </button>
            }
          },
     ], [exams?.current_page, exams?.per_page, perPage])

  return (
    <div className='p-3 rounded-lg bg-white'>
        <div className='max-[500px]:hidden'>
            <UpdatedRequestTable
                columns={columns}
                data={exams?.data}
                isLoading={isLoading}
          />
        </div>
        <div className='md:hidden grid grid-cols-1 gap-5'>
           {
              exams?.data?.length ? 
               exams?.data?.map( exam => {
                 return (
                  <ul key={exam?.id} className="border border-gray-200 rounded-lg mb-8 p-0">
                  <li className="flex justify-between py-3 px-4">
                     <span className="text-lg">Exam Name:&nbsp;</span>
                     <span className="text-lg">
                        {exam?.exam_name}
                     </span>
                  </li>
                  <li className="flex justify-between bg-gray-100 py-4 px-4">
                     <span className="text-lg">Duration:&nbsp;</span>
                     <span className="text-lg">
                     {exam?.exam_duration}
                     </span>
                  </li>
                  <li className="flex justify-between py-4 px-4">
                     <span className="text-lg">Questions:&nbsp;</span>
                     <span className={`text-lg`}>
                        { exam?.number_of_questions_answered ? 
                          <span className='flex'>
                              <del className='text-red-500'>{exam?.number_of_questions}</del>
                              <span className='ml-2'>
                                { parseInt(exam?.number_of_questions)  - exam?.number_of_questions_answered}
                              </span>
                          </span>
                        : exam?.number_of_questions } 
                     </span>
                  </li>
                  <li className="flex  bg-gray-100 justify-between py-4 px-4">
                    <div className='flex items-center'>
                      <span className="text-lg">Start Date:&nbsp;</span>
                      <span className={`text-lg ml-4`}>
                          {`${ new Date(exam?.start_date).toLocaleDateString()}`}
                      </span>
                      </div>
                      <div className='flex items-center'>
                      <span className="text-lg">End Date:&nbsp;</span>
                      <span className={`text-lg ml-4`}>
                          {`${ new Date(exam?.end_date).toLocaleDateString()}`}
                      </span>
                      </div>
                  </li>
                  <li className="flex justify-between py-4 px-4">
                    <div className='flex items-center'>
                      <span className="text-lg">Start Time:&nbsp;</span>
                      <span className={`text-lg ml-4`}>
                       {`${convertTo12Hour(exam?.start_time)}`}
                      </span>
                      </div>
                      <div className='flex items-center'>
                      <span className="text-lg">End Time:&nbsp;</span>
                      <span className={`text-lg ml-4`}>
                        {`${convertTo12Hour(exam?.end_time)}`}
                      </span>
                      </div>
                  </li>
                  <li className="flex justify-between bg-gray-100 py-4 px-4">
                     <span className="text-lg">Action:&nbsp;</span>
                     <button 
                       className='bg-primary rounded-full text-white py-2 px-5 text-sm'
                       onClick={ () => handleActionClick(exam)}
                      >
                        { 
                          exam?.exam_is_locked ? 'Unlock Exam' : 
                          exam?.number_of_questions_answered ? 'Continue' :  'Start Exam'
                        }
                    </button>
                  </li>
               </ul>
                 )
               } )
              : null
           }
        </div>
       {
           isLocked ?
             <UnlockAssessmentModal 
                refetch={refetch} closeModal={setIsLocked} 
             />
           : null
         }
    </div>
  )
}

export default VirtualExams