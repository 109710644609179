import React from 'react'
import NotificationModal from '../../../components/modal/NotificationModal'
import logo from "../../../images/TestAssessify.png";
import toast from 'react-hot-toast';
import { useMutation } from 'react-query';
import { api } from '../../../api';
import { useNavigate } from 'react-router-dom';

const EmailVerificationModal = ({email, setShowNotification}) => {

    const {mutateAsync, isLoading} = useMutation(api.resendMail)
    const  navigate = useNavigate()

    const resentMail = async (payload) => {
        try {
            toast.loading('Resending Mail...')
            const response = await mutateAsync({ 
             email,
             base_url: `${window.location.origin}/verification`
            });
            toast.remove()
            toast.success(response.message)
            setTimeout( () => {
                navigate(`/verification?email=${email}`)
            }, 500)
        } catch (error) {
            toast.remove();
            toast.error(error.response?.data?.message);
        }
    };

  return (
    <React.Fragment>
        <NotificationModal
           onClose={ () => setShowNotification(false) } 
           enableClose={true}
        >
            <div className="h-full -mt-8 -ml-3">
            <img
                src={logo}
                alt="Test Assessify"
                className="w-56 h-full object-contain"
            />
            </div>
            <h5 className="text-3xl mt-3 text-darkBlue font-semibold">
                Email Verification Required!
            </h5>
            <p className='text-lg text-primary   underline mt-5'>
                Open the email that was been sent to your mailbox after registration
                and click on the "Verify Email" button.
            </p> 
            <h6 className="mt-5 text-lg Didn’t receive the email?">
               <strong className='text-xl text-darkBlue'>
                 Didn’t receive the email?
                </strong> Check your spam or junk folder or click the button below to 
               resent verification mail
            </h6>
            <button 
              onClick={resentMail} disabled={isLoading}
              style={{ opacity : isLoading ? 0.5 : 1 }}
              className='mt-8 bg-primary py-3 px-12 text-white text-lg rounded-lg'
            >
              {isLoading ? 'Resending Mail...' : 'Resent Verification Mail'} 
            </button>
       </NotificationModal>
    </React.Fragment>
  )
}

export default EmailVerificationModal