const { createApiClient } = require("./client");

const client = createApiClient()

export const profile = {
   // get educations 
    getUserEducationListv2: (id) =>
        client.get(`/v2/candidate/${id}/education`).then(({ data }) => data),

    createUserEducationv2: ({id, payload}) =>
        client
          .post(`/v2/candidate/${id}/education`, { ...payload })
          .then(({ data }) => data),
    
    getUserBiodatav2: ({id}) =>
      client.get(`/v2/candidate/${id}/bio-data`).then(({ data }) => data),

    getUserExperienceListv2: ({id}) =>
        client.get(`/v2/candidate/${id}/experience`).then(({ data }) => data),

    getNyscv2: ({id}) =>
        client.get(`/v2/candidate/${id}/nysc-data`).then(({ data }) => data),
     
    getProfileStatusv2: ({id}) =>
        client.get(`/v2/candidate/${id}/status`).then(({ data }) => data),

    updateUserBiodatav2: ({id, payload}) =>
        client
          .patch(`/v2/candidate/${id}/bio-data`, { ...payload })
          .then(({ data }) => data),

    getInstitutionsv2: (search) =>
        client
        .get(
        `v2/institutions?per_page=10${
            typeof search === "string" ? `&search=${search}` : ""
        }`
        )
        .then(({ data }) => data),

    getCoursesv2: (search) =>
        client
        .get(
        `v2/courses?per_page=10${
            typeof search === "string" ? `&search=${search}` : ""
        }`
        )
        .then(({ data }) => data),

    getStatesv2: () => client.get(`v2/states`).then(({ data }) => data),

    getDegreesv2: () => client.get(`v2/degrees`).then(({ data }) => data),

    getClassOfDegreesv2: () =>
    client.get(`v2/class-of-degrees`).then(({ data }) => data),

 updateUserEducationv2: ({id, data, userId}) =>
    client
        .put(`/v2/candidate/${userId}/education/${id}`, {...data})
        .then(({ data }) => data),

deleteUserEducationv2: (id, userId) =>
 client
    .delete(`/v2/candidate/${userId}/education/${id}`)
    .then(({ data }) => data),

createUserExperiencev2: ({id,payload}) =>
 client
    .post(`/v2/candidate/${id}/experience`, { ...payload })
    .then(({ data }) => data),
       
updateUserExperiencev2: ({id, data, userId}) =>
 client
    .put(`/v2/candidate/${userId}/experience/${id}`, {...data })
    .then(({ data }) => data),

deleteUserExperiencev2: (id, userID) =>
    client
        .delete(`/v2/candidate/${userID}/experience/${id}`)
        .then(({ data }) => data),

AddNyscDatav2: (payload, id) =>
    client
        .post(`/v2/candidate/${id}/nysc-data`, { ...payload })
        .then(({ data }) => data),

 validateProfilev2: (payload) =>
    client
        .post(`v2/candidate/update-profile/${payload}`)
        .then(({ data }) => data),
}