import React, { useEffect, useState } from 'react'
import Spinner from '../../../components/Spinner'
import { useSearchParams, useNavigate, Link } from 'react-router-dom';
import toast from 'react-hot-toast';
import EmailVerificationModal from '../../account/components/EmailVerificationModal';

const Redirect = () => {
    const [showNotification, setShowNotification] = useState(false)
    const [searchParams] = useSearchParams();
    const email = searchParams.get('email');
    const name = searchParams.get('name');
    const token = searchParams.get('token');
    const id = searchParams.get('id');
    const verified = searchParams.get('verified');
    const navigate = useNavigate() 

  useEffect( () => {
   if(verified !== '1'){
     return setShowNotification(true)
   }
   sessionStorage.setItem("userToken",token);
   sessionStorage.setItem("view", "showSideBar");
   sessionStorage.setItem('user', JSON.stringify({
     token,
     account_type: 'candidate',
     user: { user_id: id, name, email, }
   }))
   setTimeout( () => navigate('/candidate/assessments/view-assessments') , 500 )
  }, [] )

  return (
    <section className='mt-12'>
            { showNotification ?
               <EmailVerificationModal setShowNotification={setShowNotification} email={email} />
             : 
             <div>
                <Spinner text='Verifying...' />
            </div>
            }
    </section>
  )
}

export default Redirect