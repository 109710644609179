import React, { useEffect, useState } from 'react'
import logo from "../../images/TestAssessify.png";
import { useSearchParams, useNavigate, Link } from 'react-router-dom';
import { api } from '../../api';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { greetTime } from './login';
import OTPInput from 'react-otp-input';
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const ResetPassword = () => {

    const [searchParams] = useSearchParams();
    const email = searchParams.get('email');
    const verificationOtp = searchParams.get('otp');
    const [otp, setOtp] = useState('');
    const navigate = useNavigate();
    const { register, handleSubmit, setValue, watch, formState: { errors } } = useForm();
    const [showPassword, setShowPassword] = useState(false);

    const passwordVerificationMutation = useMutation(api.resetUserPassword);

    useEffect(() => {
        if (email) setValue('email', email);
        if (verificationOtp) setOtp(verificationOtp);
    }, []);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const onSubmit = async (payload) => {
        if (otp.length < 6) {
            return toast.error('Error! Incomplete Otp Provided.');
        }
        try {
            const response = await passwordVerificationMutation.mutateAsync({ ...payload, otp });
            sessionStorage.setItem("userToken", response?.data?.token);
            sessionStorage.setItem("user", JSON.stringify(response?.data));  
            toast.success(response?.data?.message)
            setTimeout( () => {
              navigate('/candidate/')
            }, 500)
        } catch (error) {
            toast.remove();
            toast.error(error.response?.data?.message);
        }
    };
    // Watch password fields for validation
    const password = watch("password");

    return (
        <div className="bg-white p-4 lg:p-6">
            <div className="w-full pb-4 h-26 bg-inherit sticky top-0 cursor-pointer">
                <div className="h-full">
                    <img
                        src={logo}
                        alt="Test Assessify"
                        className="w-56 h-full object-contain"
                    />
                </div>
            </div>
            <div className="flex justify-center items-center mt-20 2xl:mt-0 2xl:h-screen">
                <div className="bg-cover bg-no-repeat bg-hero-img w-full md:w-[600px] lg:w-[900px] 2xl:w-[1440px] 2xl:h-[800px] rounded-xl p-2 lg:p-10 sm:shadow-2xl">
                    <div className="my-2 shadow-2xl rounded-[8px] bg-white w-full lg:w-1/2 2xl:h-[600px] 2xl:mt-28 p-4 2xl:p-6">
                        <div className="flex flex-col w-full">
                            <div className="flex flex-col justify-center lg:px-4 pt-8 my-auto md:justify-start md:pt-0 2xl:px-10">
                                <form
                                    className="flex flex-col pt-3 md:pt-8"
                                    onSubmit={handleSubmit(onSubmit)}
                                >
                                    <div className="2xl:mb-10">
                                        <div className="flex md:items-center justify-start w-full overflow-hidden">
                                            <h1 className="text-3xl md:w-3/4 object-contain font-medium text-darkBlue">
                                                Good {greetTime()}
                                            </h1>
                                        </div>
                                        <p className="my-2 text-baseFont text-slate-500">
                                            Please reset your account password
                                        </p>
                                    </div>
                                    <div className='otp-form py-3'>
                                        <OTPInput
                                            value={otp}
                                            onChange={setOtp}
                                            numInputs={6}
                                            inputType='tel'
                                            renderSeparator={<span className='text-lg font-bold text-primary px-1'>-</span>}
                                            renderInput={(props) => <input {...props} />}
                                        />
                                    </div>
                                    <div className="flex flex-col pt-5">
                                        <div className="flex relative mb-4 2xl:mb-10">
                                            <span className="inline-flex rounded-l-lg items-center px-3 border-t bg-white border-l border-b border-gray-300 text-gray-500 shadow-sm text-sm">
                                                <svg
                                                    width="15"
                                                    height="15"
                                                    fill="currentColor"
                                                    viewBox="0 0 1792 1792"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <path d="M1792 710v794q0 66-47 113t-113 47h-1472q-66 0-113-47t-47-113v-794q44 49 101 87 362 246 497 345 57 42 92.5 65.5t94.5 48 110 24.5h2q51 0 110-24.5t94.5-48 92.5-65.5q170-123 498-345 57-39 100-87zm0-294q0 79-49 151t-122 123q-376 261-468 325-10 7-42.5 30.5t-54 38-52 32.5-57.5 27-50 9h-2q-23 0-50-9t-57.5-27-52-32.5-54-38-42.5-30.5q-91-64-262-182.5t-205-142.5q-62-42-117-115.5t-55-136.5q0-78 41.5-130t118.5-52h1472q65 0 112.5 47t47.5 113z"></path>
                                                </svg>
                                            </span>
                                            <input
                                                type="email"
                                                id="email"
                                                readOnly
                                                className="flex-1 appearance-none rounded-r-lg border border-gray-300 w-full py-4 2xl:py-8 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-[#88a848] focus:border-transparent"
                                                {...register("email", { required: true })}
                                                placeholder="Enter your email"
                                            />
                                        </div>
                                    </div>
                                    <section className='mt-5'>
                                        <label className='text-sm'> New Password </label>
                                        <div className="relative flex w-full">
                                            <input
                                                type={showPassword ? "text" : "password"}
                                                id="password"
                                                className="flex-1 appearance-none border-y border-gray-300 w-full py-4 px-4 2xl:py-8 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                {...register("password", {
                                                    required: "Password is required",
                                                    minLength: { value: 6, message: "Password must be at least 6 characters" }
                                                })}
                                                placeholder="Enter your password"
                                            />
                                            <button
                                                type="button"
                                                onClick={togglePasswordVisibility}
                                                className="inline-flex rounded-r-lg appearance-none items-center px-3 border-t bg-white border-r border-b border-gray-300 text-gray-500 shadow-sm text-sm cursor-pointer focus:outline-none"
                                            >
                                                {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                                            </button>
                                        </div>
                                        {errors.password && <p className="text-red-500 mt-2">{errors.password.message}</p>}
                                    </section>
                                    <section className='mt-5'>
                                        <label className='text-sm'>Confirm New Password</label>
                                        <div className="relative flex w-full">
                                            <input
                                                type={showPassword ? "text" : "password"}
                                                id="password_confirmation"
                                                className="flex-1 appearance-none border-y border-gray-300 w-full py-4 px-4 2xl:py-8 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none"
                                                {...register("password_confirmation", {
                                                    required: "Please confirm your password",
                                                    validate: value => value === password || "Passwords do not match"
                                                })}
                                                placeholder="Confirm your password"
                                            />
                                            <button
                                                type="button"
                                                onClick={togglePasswordVisibility}
                                                className="inline-flex rounded-r-lg appearance-none items-center px-3 border-t bg-white border-r border-b border-gray-300 text-gray-500 shadow-sm text-sm cursor-pointer focus:outline-none"
                                            >
                                                {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
                                            </button>
                                        </div>
                                        {errors.password_confirmation && <p className="text-red-500 mt-2">{errors.password_confirmation.message}</p>}
                                    </section>
                                    <button
                                        type="submit"
                                        disabled={passwordVerificationMutation.isLoading}
                                        style={{ opacity: passwordVerificationMutation.isLoading ? 0.5 : 1 }}
                                        className="w-full my-5 px-4 2xl:py-4 py-2 rounded-lg text-base font-semibold text-center text-white transition duration-200 ease-in bg-[#88a848] shadow-md focus:outline-none focus:ring-2"
                                    >
                                        <span className="w-full text-xl 2xl:text-2xl">
                                            {passwordVerificationMutation.isLoading ? 'Loading...' : 'Reset Password'}
                                        </span>
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ResetPassword;
